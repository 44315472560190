<h2 mat-dialog-title>Change password</h2>
<form class="form" [formGroup]="userPasswordForm">

    <mat-dialog-content class="mat-typography">
        <mat-form-field class="form-field">
            <mat-label>Old password *</mat-label>
            <input [type]="isOldPassVisible? 'text': 'password'" autocomplete="on" matInput
                formControlName="old_password">
            <button mat-button matSuffix mat-icon-button (click)="toggleOldPasswordVisibility()">
                <mat-icon>{{isOldPassVisible? 'visibility':'visibility_off'}}</mat-icon>
            </button>
            <mat-error *ngIf="userFormControl.old_password.hasError('required')">
                The old password is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field">
            <mat-label>New password *</mat-label>
            <input [type]="isNewPassVisible? 'text': 'password'" autocomplete="on" matInput
                formControlName="new_password">
            <button mat-button matSuffix mat-icon-button (click)="toggleNewPasswordVisibility()">
                <mat-icon>{{isNewPassVisible? 'visibility':'visibility_off'}}</mat-icon>
            </button>
            <mat-error *ngIf="userFormControl.new_password.hasError('required')">
                The new password is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="userFormControl.new_password.hasError('minlength')">
                The new password must be <strong>at least 4 characters.</strong>
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-raised-button type="submit" color="primary" (click)="onSubmit()" cdkFocusInitial>Change
            password</button>
    </mat-dialog-actions>
</form>