import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { AuthApis } from '../../auth.constants';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  userPasswordForm: FormGroup;
  isOldPassVisible = false;
  isNewPassVisible = false;


  constructor(private fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<ChangePasswordFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private httpClient: HttpClient) { }



  ngOnInit(): void {
    this.userPasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required, Validators.minLength(4)]]
    })
  }

  get userFormControl() {
    return this.userPasswordForm.controls;
  }

  toggleOldPasswordVisibility() {
    this.isOldPassVisible = !this.isOldPassVisible;
  }

  toggleNewPasswordVisibility() {
    this.isNewPassVisible = !this.isNewPassVisible;
  }

  onSubmit() {
    if (this.userPasswordForm.valid) {
      this.httpClient.post(`${AuthApis.changePassword}`, this.userPasswordForm.value).subscribe(
        response => {
          this.dialogRef.close();
        });
    }
  }
}
