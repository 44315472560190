import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreApis } from '../core.constants';
import { map, catchError } from 'rxjs/operators';
import { ResponseHandlerService } from './response-handler.service';
import { FlashMessageType } from 'src/app/shared/components/flash-message/flash-message.constants';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  constructor(private httpClient: HttpClient, private responseHandlerService: ResponseHandlerService) { }

  addToWishlist(id) {
    return this.httpClient.post(`${CoreApis.addToWishlist}`, { property_id: id }).pipe(map((response: any) => {
      this.responseHandlerService.showMessage('Success', response.message, FlashMessageType.green);
      return response;
    })
      , catchError(err => {
        if (err.status == 401) {
          this.responseHandlerService.showMessage('Warning', 'Please login for adding to wishlist', FlashMessageType.orange);
          return
        }
        this.responseHandlerService.handleError(err)
        return throwError(err);
      }));
  }

  removeFromWishlist(id) {
    return this.httpClient.post(`${CoreApis.removeFromWishlist}`, { id }).pipe(map((response: any) => {
      this.responseHandlerService.showMessage('Success', response.message, FlashMessageType.green);
      return response;
    }), catchError(err => {
      if (err.status == 401) {
        this.responseHandlerService.showMessage('Warning', 'Please login to remove from wishlist', FlashMessageType.orange);
        return
      }
      this.responseHandlerService.handleError(err)
      return throwError(err);
    }));
  }
}
